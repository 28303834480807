import React from 'react'
import Dna from '../assets/dna.png'
import Hospital_Bed from '../assets/stretcher.png'
import Printer from '../assets/printer.jpg'
import Rfid_Chip from '../assets/rfid_chip.jpg'
import Lab_Technicians from '../assets/lab.png'
import { ImScissors, ImFolderOpen, ImArrowRight } from 'react-icons/im'
import { GiCurvyKnife } from 'react-icons/gi'




const HowItWorks = () => {
  return (
    <div id='howitworks' className='pb-20 flex flex-col items-center pt-20'>
      <p className='font-semibold text-3xl font-serif mt-16'>How it Works</p>
      <img src={Dna} className='h-14 w-60 object-contain   ' />
      <p className='text-sm text-gray-500 '>Olynth's platform is designed to be user-friendly, powerful, and dynamic<p className='flex justify-center'> while our consulting process is informative, quick, and efficient.</p></p>

      <div className='flex gap-x-10 pt-10'>

        <div className='flex w-1/2 bg-white gap-x-10 shadow-md border'>
          <img src={Hospital_Bed} className='h-52 w-40 object-left object-cover' />
          <p className='font-bold text-5xl text-white bg-[#058459] h-fit w-fit px-2 py-4 relative -left-16 top-14'>1.</p>


          <div className='flex flex-col gap-y-2 relative -left-20'>
            <p className='text-black  font-bold pt-4'>Site-Visit or Virtual Consultation </p>
            <p className='text-sm text-slate-500 w-80'>Olynth's team will conduct a site-visit of interested hospitals or centers or connect with hospitals virtually to best assess each institution's unique needs, locate a vacant area to set up the printing department in, and overall understand the client.</p>
          </div>
        </div>

        <div className='flex w-1/2 bg-white gap-x-10 shadow-md border'>
          <img src={Printer} className='h-52 w-40 object-left object-cover' />
          <p className='font-bold text-5xl text-white bg-[#058459] h-fit w-fit px-2 py-4 relative -left-16 top-14'>2.</p>


          <div className='flex flex-col gap-y-2 relative -left-20'>
            <p className='text-black  font-bold pt-4'>Setting Up The Printer </p>
            <p className='text-sm text-slate-500 w-80'>Based on the client's unique needs, Olynth will connect hospitals to a fitting 3D-printer and help set up the printer and RFID-embedding machine.</p>
          </div>

        </div>

      </div>




      <div className='flex gap-x-10 pt-10'>
        <div className='flex w-1/2 bg-white gap-x-10 shadow-md border'>
          <img src={Rfid_Chip} className='h-52 w-40 object-left object-cover' />
          <p className='font-bold text-5xl text-white bg-[#058459] h-fit w-fit px-2 py-4 relative -left-16 top-14'>3.</p>


          <div className='flex flex-col gap-y-2 relative -left-20'>
            <p className='text-black  font-bold pt-4'>Component Sourcing </p>
            <p className='text-sm text-slate-500 w-80'>Olynth will directly sell RFID chips and carbon fiber filaments to clients and will ship parts to hospitals whenever  parts run out.</p>
          </div>
        </div>

        <div className='flex w-1/2 bg-white gap-x-10 shadow-md border'>
          <img src={Lab_Technicians} className='h-52 w-40 object-left object-cover' />
          <p className='font-bold text-5xl text-white bg-[#058459] h-fit w-fit px-2 py-4 relative -left-16 top-14'>4.</p>


          <div className='flex flex-col gap-y-2 relative -left-20'>
            <p className='text-black font-bold pt-4'>Training</p>
            <p className='text-sm text-slate-500 w-80'>Olynth's dedicated team will train hospital medical staff on how to use the 3D-printing software so they are fully functional and capable of designing customized surgical tools as needed.</p>
          </div>

        </div>
      </div>
      <p className='text-black text-4xl mt-16 font-bold'>Olynth’s 3D Printing Process</p>

      <div className=' bg-[#058459] flex flex-row gap-2 z-10 relative top-20 px-3  w-11/12 h-32 '>
        <div className='bg-[#14966b] mt-2 ml mb-1 h-50  flex justify-center items-center'>
          <p className='text-white rounded-full bg-gray-400 text-xl p-4 h-5 w-5 flex items-center justify-center relative -left-4 -top-14'>1</p>
          <ImScissors className='text-white mx-3 relative -left-3' size={50} />
          <div className='w-full flex flex-col gap-y-1 pt-10 '>
            <p className='text-white font-bold'>Initial Tool Design</p>
            <p className='text-sm font-normal text-white '>Medical staff can digitally design a brand  new or modified surgical tool on Olynth's platform.</p>
            <ImArrowRight className='text-white relative top-4 -right-64 ' size={30} />
          </div>


        </div>

        <div className='bg-[#14966b] mt-2 ml-2 mb-1 h-50  flex justify-center items-center'>
          <p className='text-white rounded-full bg-gray-400 text-xl p-4 h-5 w-5 flex items-center justify-center relative -left-2 -top-14'>2</p>
          <ImFolderOpen className='text-white mx-3 relative -left-3' size={50} />
          <div className='w-full pt-10 pl-2 '>
            <p className=' text-white font-bold'>File Conversion </p>
            <p className='text-sm font-normal text-white '>The tool design is converted into  an appropriate file format and relayed to the printer for printing.</p>
            <ImArrowRight className='text-white relative top-5 ml-5 -right-64' size={30} />

          </div>


        </div>

        <div className='bg-[#14966b] mt-2 ml-2 mb-1 h-50  flex justify-center items-center'>
          <p className='text-white rounded-full bg-gray-400 text-xl p-4 h-5 w-5 flex items-center justify-center relative -left-2 -top-14'>3</p>
          <GiCurvyKnife className='text-white mx-3 relative -left-3' size={50} />
          <div className='w-full pl-2'>
            <p className=' text-white font-bold' >Testing & Modification</p>
            <p className='text-sm font-normal text-white '>The printed surgical instrument can be tested using virtual surgery simulation and can be modified accordingly. </p>
          </div>


        </div>

      </div>
    </div>
  )
}

export default HowItWorks