import './App.css';
import Footer from './components/Footer';
import HeaderContact from './components/HeaderContact';
import { BrowserRouter as Router } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'
import Home from './components/Home'
import Feature from './components/Feature'
import logo from '../src/assets/logo.png'
import Pricing from './components/Pricing'
import { IoIosArrowDown,IoMdMenu } from 'react-icons/io'
import RFID from './components/RFID';
import ContactUs from './components/ContactUs.js';
import HowItWork from './components/HowItWorks';
import { useState } from 'react';

function App() {
  return (

    <Router>
      <div>
        <HeaderContact />
        <NavigationBar />
        <Home />
        <Feature />
        <RFID />
        <Pricing />
        <ContactUs />
        <HowItWork />
        {/* <Footer /> */}
      </div>
    </Router>

  )
}

export default App;

const NavigationBar = () => {



  const [selected, setSelected] = useState('Home')



  const NavBarItems = ({ label, icon, selected, setSelected }) => {

    return (



      <div onClick={() => setSelected(label)} className={` ${selected == label ? 'bg-[#058459] hover:bg-[#14966b]' : 'bg-white hover:bg-gray-200'} rounded-none cursor-pointer flex py-2 px-6`}>
        <p className={` ${selected != label ? 'text-gray-500' : 'text-white'} text-md font-prompt flex`} >{label}{icon}</p>
      </div>
    )
  }



  return (
    <div>
      <div className='flex fixed bg-white box-border h-20 mt-9 shadow-md items-center w-full justify-around z-20'   >

        <a href="/home">
          <img src={logo} className="rounded-full h-44 w-28 object-contain" />
        </a>

        <div className={`flex relative gap-x-2 `}>

          <Link to='#home' smooth > <NavBarItems setSelected={setSelected} selected={selected} label="Home"  /> </Link>
          <Link to='#feature' smooth>  <NavBarItems setSelected={setSelected} selected={selected} label="Features" /></Link>
          <Link to='#contactus' smooth> <NavBarItems setSelected={setSelected} selected={selected} label="Contact" /></Link>
          <Link to='#aboutus' smooth>  <NavBarItems setSelected={setSelected} selected={selected} label="Our Mission" /></Link>
          <Link to='#pricing' smooth>  <NavBarItems setSelected={setSelected} selected={selected} label="Pricing"/></Link>
  
        </div>

      </div>
    </div>

  )
}