import React, { useState } from 'react'
import { ImClock, ImEnvelop, ImLinkedin2, ImGooglePlus, ImFacebook, ImTwitter, ImPhone } from 'react-icons/im'
import { Link } from 'react-router-dom'
import Logo from '../assets/logo.png'
import { IoIosArrowDown, IoIosMenu, IoMdMenu } from 'react-icons/io'


const HeaderContact = () => {

    const [activeLink, setActiveLink] = useState(false)

    return (
        <div className='flex-col flex fixed z-30 w-full' >
            <div className='bg-[#058459] flex relative justify-around h-10 text-sm' >
               

                <div className='text-white flex items-center gap-10'>

                   
                    <div className='flex items-center gap-1'>
                        <ImEnvelop size={13} />
                        <p>olynth.co@gmail.com</p>
                    </div>

                </div>
          
            </div>

        </div>
    )
}

export default HeaderContact