import React from 'react'
import { ImQuotesLeft, ImQuotesRight } from 'react-icons/im'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUs = () => {
 
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_s7lvo5r', 'template_t9ic63i', e.target, 'sNLB3hVB1ZukAgU7Z')
      .then((res) => {
        toast.success('Message Sent!', {
          position: toast.POSITION.BOTTOM_CENTER
        });

      }, (err) => {
        alert(err.text)
      })
    e.target.reset()
  }

  return (
    <div id='contactus' className='flex gap-x-10 h-fit  px-16 pt-36'>
      <div className='bg-[#058459] h-fit w-2/5 p-5 flex flex-col gap-y-5'>
        <p className='text-white rounded-3xl cursor-default bg-slate-400 w-fit py-3 px-7 '>Contact Us</p>
        <form onSubmit={sendEmail}>
          <div className='flex gap-4'>
            <input className='rounded-3xl p-2' placeholder='Name' name='name' />
            <input className='rounded-3xl p-2 ' placeholder='Title' name='title' />
          </div>
          <div className='flex gap-4 my-5'>
            <input className='rounded-3xl p-2' placeholder='Email' name='email' />
            <input className='rounded-3xl p-2' placeholder='Phone Number' name='phonenumber' />
          </div>
          <textarea className='rounded-xl w-full h-52 p-2' placeholder='Message, Reason for Contact' name='message' />
          <div className='rounded-xl bg-gray-400 hover:bg-slate-300 p-2 w-fit' >
            <input type='submit' value='Send Message'></input>
          </div>
          <ToastContainer  autoClose={5000}  />

        </form>
      </div>

    </div>
  )
}

export default ContactUs