import React from 'react'
import { ImFacebook, ImGooglePlus, ImTwitter } from 'react-icons/im'
import Nurse from '../assets/nurse.jpg'
import RFIDpic from '../assets/rfidtracking.png'

const RFID = () => {
  return (
    <div className=' flex bg-white w-full gap-x-10 py-20 justify-center ' id='rfid'>
      <img src={RFIDpic} className=' h-80 mt-10  object-contain' />

      <div className='flex flex-col gap-4 mt-10 '>
        <p className='text-3xl font-serif font-semibold text-gray-500 text-opacity-80'>How it Works</p>
        <p className='text-3xl font-serif font-semibold'>RFID Tracking</p>
        <p className='text-sm font-serif font-medium text-gray-500 text-opacity-80 '> 1. RFID tags are embedded onto each reusable tool you print with Olynth <br /> during the 3D-printing process.</p>
        <p className='text-sm font-serif font-medium text-gray-500 text-opacity-80'> 2. These tags can be connected to a monitor or central dashboard in an operating <br /> room via Bluetooth.</p>
        <p className='text-sm font-serif font-medium text-gray-500 text-opacity-80'> 3. The RFID tags then relay and display key information regarding each tool <br /> such as unique IDs, live usage time, set order lists, current location and  <br /> distance from the operating room, and more onto the connected screen.</p>
     
      </div>

    </div>
  )
}

export default RFID