import React from 'react'
import Docs from '../assets/alldoctors.jpg'
import crossarmed from '../assets/crossarmed.png'
import signature from '../assets/signature.png'
import { ImCheckboxChecked, ImClock } from 'react-icons/im'
import { Checkbox } from '@material-tailwind/react'
import surgicalroom from '../assets/surgicalroom.png'
import printer from '../assets/printer.png'

const Home = () => {
    return (
        <div id='home'>
            <div style={{ backgroundImage: `url(${surgicalroom})`, height: '100vh', backgroundSize: '100%' }} >
                <div className='flex flex-col absolute top-40 text-white  pt-44 px-40'>
                    <div className='bg-slate-600 text-3xl py-2 font-semibold items-center flex pl-4 mr-44 '>
                        <p> The Power of</p>
                    </div>
                    <div className='bg-[#14966b] text-3xl font-semibold items-center py-5 flex pl-4 -mr-36 '>
                        <p>3D-Printed Surgical Instruments </p>
                    </div>
                    <div className='bg-[#14966b] mt-2 py-2 px-4 font-light text-xl -mr-48 '>
                        <p>Olynth allows hospitals and surgeons to design and create customized surgical <br /> tools catered to each unique operation for effective, efficient surgeries.</p>
                    </div>
                   
                </div>
            </div>

            <AboutUs />

        </div>

    )
}

export default Home

const AboutUs = () => {
    return (
        <div id='aboutus' className="flex bg-white text-gray-500 text-opacity-60 px-20 pt-32 ">
            <div className='flex flex-col w-8/12 gap-y-3  '>
                <h1 className='text-2xl font-semibold font-serif '>About</h1>
                <h2 className='text-3xl font-light font-serif pb-3'>Olynth</h2>
                <p className='text-xl'>
                    Olynth believes surgical instruments should not be confined
                    to one shape, form, or size. Gone are the days of simple tools. <br /><br />
                    We understand that every surgeon's hands, surgical
                    procedure, and patient are different. We harness the power
                    of 3-D Printing to offer the customized tools surgeons need to
                    effectively save lives, whether that means added functionality,
                    bigger tools, or more precise nozzles. Olynth's software
                    empowers doctors and hospitals to reach their full potential
                    by providing a platform to design specialized tools.
                </p>

            </div>


            <div className='absolute right-10 h-1/4 w-1/3 object-contain pt-10 '>
                <img src={printer} />
            </div>


        </div>
    )
}

