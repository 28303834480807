import React, { useState } from 'react'
import { IoMdAdd } from 'react-icons/io'
import AddedFunctionality from '../assets/addedfunctionality.png'
import MRI from '../assets/mri.jpg'
import Reuseable from '../assets/reuseable.png'
import RFID from '../assets/rfidtrack.png'
import Shape from '../assets/shape.png'
import Dna from '../assets/dna.png'
import { GiSuitcase } from 'react-icons/gi'
import { FaHeartbeat, FaHeadSideVirus } from 'react-icons/fa'
import { ImDroplet, ImTruck } from 'react-icons/im'
import { AiFillDollarCircle } from 'react-icons/ai'
import Radiology from '../assets/radiology.jpg'
import { MdDoubleArrow, MdBookmark, MdAccessTimeFilled } from 'react-icons/md'
import platformfeatures from '../assets/platformfeatures.png'
import valueinolynth from '../assets/valueinolynth.png'





const Feature = () => {

  return (
    <div className='h-fit flex flex-col pt-20' id='feature' >
      <div className='w-fit h-fit flex justify-between relative   pt-16 pb-10 bg-gray-50 gap-3 text-gray-500 text-opacity-70' >
        <div className='w-fit px-10'>
          <p className='text-3xl bg-slate-700 font-semibold text-white w-fit flex items-center p-2 px-8 mb-5'>Platform Features</p>
          <p className=' flex text-lg font-light'>
            Olynth's software offers a wide array of unique 3D-printing features to help surgeons craft the perfect surgical
            instrument. Ultimately, hospitals and doctors decide what they want and need for their medical staff, facility,
            or department but below are some of the 3D-printing aspects Olynth's platform provides to optimize tools.
          </p>

          <div className='flex flex-col my-10 gap-5 w-full' >

            <div className='flex flex-row gap-2 ' >

              <div className='flex flex-row w-1/2 gap-2'>
                <div className='bg-slate-100 h-50 w-1/3 flex justify-center items-center'>
                  <img className='h-14 w-14 object-contain' src={AddedFunctionality} />
                </div>

                <div className='w-full bg-slate-100 '>
                  <p>Added Functionality</p>
                  <p className='text-xs font-normal w-fit'> Add parts, extra teeth, handles, clamps, or whatever an operation calls for to a pre-existing tool. This reduces the number of tools
                    required and makes surgery efficient and capable.
                  </p>
                </div>
              </div>

              <div className='flex flex-row w-1/2 gap-2'>
                <div className='bg-slate-100 h-50 w-1/3 flex justify-center items-center'>
                  <img className='h-20 w-20 object-contain' src={RFID} />
                </div>

                <div className='w-full bg-slate-100 '>
                  <p>RFID Tracking</p>
                  <p className='text-xs font-normal w-fit'>Olynth's innovation doesn't stop at customizability.<br /> RFID tags are embedded onto each reusable tool printed. These tags help with operating room logistics. They indicate tool location, usage duration in real-time, unique ID numbers, and a whole lot more.</p>
                </div>
              </div>



            </div>

            <div className='flex flex-row w-1/2 gap-2'>
              <div className='bg-slate-100 h-50 w-1/3 flex justify-center items-center'>
                <img className='h-20 w-20 object-contain' src={Shape} />
              </div>

              <div className='w-full bg-slate-100 '>
                <p>Size and Shape</p>
                <p className='text-xs font-normal w-fit'> Decide on the unique size dimensions and shape of
                  the tool to cater towards the size and fit of surgeon
                  hands and the procedure or patient in question.
                </p>
              </div>
            </div>

            <div className='flex flex-row w-full gap-2'>
              <div className='bg-slate-100 h-50 w-1/3 pr-3 flex justify-center items-center'>
                <img className='h-20 w-28 object-contain pl-3' src={Reuseable} />
              </div>

              <div className='bg-slate-100 '>
                <p>A Reusable Toggle</p>
                <p className='text-xs font-normal w-fit'>Some tools which are of more general-use should be kept around, others not so much. Olynth's platform
                  puts the power in the hands of the hospital, allowing surgeons to dictate whether a tool is reusable and thus
                  the material make-up of the instrument. This way, hospitals can decide what usability they pay for. Afterall,
                  sterilization of instruments is expensive, don't be charged more for a tool which is only needed once.
                </p>
              </div>
            </div>

          </div>


        </div>

        <div className=' relative'>
          <div className='flex mt-5' >
            <img src={platformfeatures} className=' h-80 object-contain ' />
          </div>

          <div>
            <p className='text-2xl text-black font-medium font-serif'>Why Choose Us?</p>
            <p className='bg-slate-100 w-fit flex items-center mt-4 p-2 text-gray-500 gap-4 '> Only Vendor Of RFID-Tagged 3D-Printed Tools</p>
            <p className='bg-slate-100 w-80 flex items-center mt-2 p-2 text-gray-500 gap-4'>We Use Carbon Fiber Filaments, Not Expensive Titanium or Steel</p>
            <p className='bg-slate-100 w-fit flex items-center mt-2 p-2 text-gray-500 gap-4'> Fully Customizable Tools</p>

          </div>
        </div>

      </div>

      <div className='bg-gray-200 bg-opacity-50 h-fit pb-20 -mt-10 w-full px-20 '>
        <div className='flex flex-col justify-center items-center '>
          <p className='text-4xl font-medium bg-[#efefef] mt-10 p-3 px-40 w-fit'>The Value in Olynth</p>
          <img src={Dna} className='h-20 w-60 object-contain mr-10  ' />
          <p className='text-2xl font-medium bg-slate-100  p-3 w-fit'>The Benefits of Using Olynth's Innovative Services. The Value in Olynth: </p>

          <div className='bg-white h-full w-full mt-16 text-sm'>
            <div className='flex w-full border-b items-center text-[#058459] '>

              <p className=' w-1/4 flex flex-col items-center px-10 py-2'> <AiFillDollarCircle size={40} className="items-center w-full flex" />Savings</p>
              <p className=' w-1/4 flex flex-col items-center px-10 py-2 border-l '><FaHeartbeat size={40} className="items-center flex w-full " />Accurate Surgeries</p>
              <p className=' w-1/3 flex flex-col items-center px-10 py-2 border-l  '><MdAccessTimeFilled size={40} className="items-center w-full flex" />Reduced Development Time </p>
              <p className=' w-1/4 flex flex-col items-center px-10 py-2 border-l  '><MdBookmark size={40} className="items-center w-full flex" />Environmental Benefits</p>
              <p className=' w-1/4 flex flex-col items-center px-10 py-2 border-l  '><ImTruck size={40} className="items-center w-full flex" />Empowered Logistics</p>

            </div>

            <div className='flex mt-10'>
              <img src={valueinolynth} className='h-96 w-fit object-contain ' />
              <div className='flex flex-col'>
                <p className=' text-gray-500 text-opacity-70 text-2xl'>What's the Value in This?</p>
                <p className='text-3xl'>How Olynth Helps</p>
                <p className='text-gray-500 text-opacity-70 text-lg py-6'>There are various benefits to using 3D-printed tools from Olynth both at the facility and organizational level as well as the surgical level.</p>
                <p className='flex item center text-xs my-2 p-2'><MdDoubleArrow size={14} className='text-[#058459] mr-3 mt-2' />Sterilization expenses can costs hospitals over $100,000 annually, the option of Olynth's one-time use tools <br /> reduces this expense for hospitals. The reusable option greatly reduces impact on the environment as well.</p>
                <p className='flex item center text-xs p-2'><MdDoubleArrow size={14} className='text-[#058459] mr-3 mt-2' />Customizing tools to the exact preferences of the surgeon, patient, and procedure allows for accurate, <br /> effective surgery as surgical instruments are capable of performing unique new tasks.</p>
                <p className='flex item center text-xs my-2 p-2'><MdDoubleArrow size={14} className='text-[#058459] mr-3 mt-3' />Facilities usually must put in orders or wait for new editions of tools to be developed that meet their needs. <br />These long production lines can be avoided with 3D-printing which takes around 6-8 hours to produce <br/> tools instead. Combined with our tracking tags, logistics can be greatly improved overall.</p>
                {/* <p className='cursor-pointer rounded-md w-fit ml-2 py-2 px-6 mb-10 bg-[#00a3c8] hover:bg-[#52c2db] text-white'>View Details</p> */}
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>
  )
}

export default Feature
