import React from 'react'
import Dna from '../assets/dna.png'
import whitestethoscope from '../assets/whitestethescope.png'
import Whitedroplets from '../assets/whitedroplet.png'
import Bookmark from '../assets/bookmark.png'
import Medal from '../assets/medal.png'
import Premium from '../assets/pre.png'
import Direct from '../assets/direct.png'
import { MdDoubleArrow } from 'react-icons/md'
import { HashLink as Link } from 'react-router-hash-link'



const Pricing = () => {
  return (
    <div id='pricing' className='bg-[#f0f0f0] pb-20 flex flex-col items-center px-16 pt-20'>
      <p className='font-semibold text-3xl font-serif mt-16'>PRICING</p>
      <img src={Dna} className='h-14 w-60 object-contain   ' />
      <p className=' text-gray-500 pl-20 '>Olynth offers pricing plans and pricing flexibility to meet all the needs of hospitals and surgeons. Olynth recognizes that tools are needed on an on-demand basis for surgeries which arise suddenly. That’s why we create multiple ways for our customers to pay so they can pay for only what they need and nothing more. </p>
      <div className='flex gap-10 mt-20'>
        <div className='bg-white h-fit pb-10 w-80 flex flex-col items-center gap-y-5'>
          <img src={Medal} className='rounded-full bg-[#058459] h-20 w-20 object-contain object-center drop-shadow-lg relative -top-10' />
          <p className='text-2xl font-bold '>Standard Subscription</p>
          <p className='flex flex-col text-xs text-gray-500 pt-5'>Hospitals pay a fixed monthly fee for use <p> of Olynth's platform to print a set number of </p><p className='flex justify-center'>tools per month </p></p>
          <p className='text-[#058459] text-3xl font-serif pt-7'>$1,000 / Per Month </p>
          <Link to='#contactus' smooth ><p className='cursor-pointer rounded-none bg-[#058459] hover:bg-[#14966b] text-white px-4 py-2 mt-20'>Purchase Now</p></Link>
          <p className='flex items-center gap-2 font-medium ml-3 '>Learn More <Link to='#contactus' smooth ><MdDoubleArrow className='text-[#058459]' /> </Link></p>
        </div>

        <div className='bg-white h-fit pb-10 w-80 flex flex-col items-center gap-y-5'>
          <img src={Premium} className='rounded-full bg-[#058459] h-20 w-20 object-contain  drop-shadow-lg  relative -top-10' />
          <p className='text-2xl font-bold '>Premium Subscription</p>
          <p className='text-[#058459] text-3xl font-serif mt-28'>Coming Soon</p>
          <Link to='#contactus' smooth > <p className='cursor-pointer rounded-none bg-[#058459] hover:bg-[#14966b] text-white px-4 py-2 mt-20'>Purchase Now</p></Link>
          <p className='flex items-center gap-2 font-medium ml-3 '>Learn More <Link to='#contactus' smooth ><MdDoubleArrow className='text-[#058459]' /> </Link></p>
        </div>

        <div className='bg-white h-fit pb-10  w-80 flex flex-col items-center gap-y-5'>
          <img src={Direct} className='rounded-full bg-[#058459] h-20 w-20 object-contain drop-shadow-lg relative -top-10' />
          <p className='text-2xl font-bold '>Direct Purchasing</p>
          <p className='text-[#058459] text-3xl font-serif mt-28'>Coming Soon</p>
          <Link to='#contactus' smooth > <p className='cursor-pointer text-white rounded-none bg-[#058459] hover:bg-[#14966b] px-4 py-2  mt-20'>Purchase Now</p></Link>
          <p className='flex items-center gap-2 font-medium ml-3 '>Learn More <Link to='#contactus' smooth ><MdDoubleArrow className='text-[#058459]' /> </Link></p>
        </div>

      </div>
    </div >
  )
}

export default Pricing